import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN, trimToWords } from '../components/common/Misc';

const Alternatives = () => {

    const [response, setResponse] = useState(null);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        fetch(`${BackendURL}/api/alternatives?populate[featured_image]=*&sort=id:desc`, { headers })
            .then(response => response.json())
            .then(data => setResponse(data.data));
    }, []);

    // console.log(response);

  return (
    <>
    {response ? 
    <main>
        <section className='blogs-block'>
            <div className='blogs-inner-wr hero-header'>
                <div className='container px-lg-5'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10 pe-lg-5 text-center'>
                            <h1 className='text-white mb-4'>Fonimo Alternatives</h1>
                            <p className='text-white'>Explore top alternatives to Fonimo for your communication needs. Compare features, benifits, and performance to find the best solution that fits your business requirements.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blogs-wr py-5'>
                <div className='container px-lg-5'>
                    <div className='blog-slider-inner-wr'>
                        <div className='blog-slider row '>
                            {response.map(({attributes},index) => {
                                var date = new Date(attributes?.createdAt);
                                // var formattedDate = `${('0' + date.getUTCDate()).slice(-2)} ${date.toLocaleString('default', { month: 'short' })} ${date.getUTCFullYear()}`;
                                return (
                                    <div key={index} className="col-lg-4 col-12 mb-4" >
                                        <div className='card'>
                                            <div className='featured_image'>
                                                <figure>
                                                    <img src={BackendURL+attributes.featured_image.data.attributes.url} className="card-img-top" alt={`${attributes.title}`} />
                                                </figure>
                                            </div>
                                            <div className="card-body" style={{minHeight: "238px"}}>
                                                <a href={`/alternative/${attributes.slug}`}>
                                                    <h5 className="card-title">{attributes.title}</h5>
                                                </a>
                                                <p className="card-text">{trimToWords(attributes.content, 10)}</p>
                                                <a href={`/alternative/${attributes.slug}`} className="btn btn-secondary">Read more</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    : ""}
    </>
  )
}

export default Alternatives