import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../components/common/Misc';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Alternative = () => {

  const { slug } = useParams();

  const [response, setResponse] = useState(null);

    useEffect(() => {
      const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
      fetch(`${BackendURL}/api/alternatives/${slug}?&populate=*`, { headers })
          .then(response => response.json())
          .then(data => setResponse(data.data[0]));
    }, [slug]);

  // console.log(response);
  return (
    <>
    {response ?
    <main>
    <section className='comn-hero-blk'>
        <div className='comn-hero-inner'>
            <div className='container px-lg-4'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 hero-content-wr pe-lg-5 text-center'>
                        <h1 className='text-white mb-4'>{response?.attributes?.title}</h1>
                        <Markdown rehypePlugins={[rehypeRaw]}>{response?.attributes?.content}</Markdown>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {response?.attributes?.comparison ? 
    <section className='Product_comparison_blk'>
      <div className='container'>
        <div className='product_comparison_inner_wr row justify-content-center'>
          <div className='col-12 text-center mb-5'>
            <h2>How Fonimo and Tragofone Stack Up: Performance and Scalability Comparison</h2>
          </div>
          <div className='comparison_wr col-lg-10 col-md-12'>
            <div className='comparison_head'>
              <div className='row'>
                <div className='col-lg-4'><h4 className='py-3 px-2 text-white'>Criteria</h4></div>
                <div className='col-lg-4'><h4 className='py-3 px-2 text-white'>Fonimo</h4></div>
                <div className='col-lg-4'><h4 className='py-3 px-2 text-white'>Tragofone</h4></div>
              </div>
            </div>
            <div className='comparison_content_wr'>
              {response.attributes.comparison.map(({Criteria,product_one_content,product_two_content}) => (
                <div className='row comparison_content'>
                  <div className='col-lg-4'><h5>{Criteria}</h5></div>
                  <div className='col-lg-4'>
                    <Markdown rehypePlugins={[rehypeRaw]}>{product_one_content}</Markdown>
                  </div>
                  <div className='col-lg-4'>
                  <Markdown rehypePlugins={[rehypeRaw]}>{product_two_content}</Markdown>
                  </div>
                </div>

              ))
              } 
            </div>
          </div>
        </div>
      </div>
    </section>
    : ""}
    </main>
    : "" }
    </>
  )
}

export default Alternative