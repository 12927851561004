import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN, trimToWords } from '../components/common/Misc';
import Meta from '../components/common/Meta';

const metaData = {
    meta : {
        meta_title: "Fonimo Blogs | All About Softphones",
        meta_desc: "Get valuable insights into the world of VoIP, SIP, and more at Fonimo Blog. Elevate your communication strategies with our expert blogs and resources. Call Us! ",
    }
}


const Blog = () => {

    const [response, setResponse] = useState(null);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        fetch(`${BackendURL}/api/posts?populate[featured_image]=*&populate[categories]=*&sort=id:desc`, { headers })
            .then(response => response.json())
            .then(data => setResponse(data.data));
    }, []);

    // console.log(response);

  return (
    <>
    <Meta value={metaData} canonical="https://fonimo.app/blog" />
    {response ? 
    <main>
        <section className='blogs-block'>
            <div className='blogs-inner-wr hero-header'>
                <div className='container px-lg-5'>
                    <div className='row g-5'>
                        <div className='col-lg-12 text-center text-lg-start'>
                            <h1 className='text-white mb-4 animated slideInDown'>Blogs and Insights - Everything About the WebRTC Softphone</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blogs-wr py-5'>
                <div className='container px-lg-5'>
                    <div className='blog-slider-inner-wr'>
                        <div className='blog-slider row '>
                            {response?.map(({attributes},index) => {
                                var date = new Date(attributes?.createdAt);
                                // var formattedDate = `${('0' + date.getUTCDate()).slice(-2)} ${date.toLocaleString('default', { month: 'short' })} ${date.getUTCFullYear()}`;
                                var formattedDate = `${('0' + date.getUTCDate()).slice(-2)} ${date.toLocaleString('default', { month: 'short' })}`;
                                return (
                                    <div key={index} className="col-lg-4 col-12 mb-4" >
                                        <div className='card'>
                                            {attributes?.featured_image?.data?.attributes ? 
                                            <div className='featured_image'>
                                                <figure>
                                                    <img src={BackendURL+attributes?.featured_image?.data?.attributes.url} className="card-img-top" alt="..." />
                                                    <figcaption>{formattedDate}</figcaption>
                                                </figure>
                                            </div>
                                            : ""}
                                            <div className="card-body" style={{minHeight: "238px"}}>
                                                {attributes?.categories?.data.length > 0 ? 
                                                <div className='post-meta'>
                                                    <div className='category'>
                                                        <span>{attributes?.categories?.data[0].attributes?.name}</span>
                                                    </div>
                                                </div>
                                                : ""}
                                            <a href={`/blog/${attributes?.slug}`}>
                                                <h5 className="card-title">{attributes?.title}</h5>
                                            </a>
                                            <p className="card-text">{trimToWords(attributes?.content, 10)}</p>
                                            <a href={`/blog/${attributes?.slug}`} className="btn btn-secondary">Read more</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    : ""}
    </>
  )
}

export default Blog