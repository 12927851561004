import React from 'react'

const FusionPbx = () => {
  return (
    <>
    <section className='comn-hero-blk'>
        <div className='comn-hero-inner'>
            <div className='container px-lg-4'>
                <div className='row justify-content-between'>
                    <div className='col-lg-6 hero-content-wr pe-lg-5'>
                        <h1 className='text-white mb-4'>Integrate FusionPBX with Softphones for Seamless VoIP Communication</h1>
                        <p className='text-white pb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <a href='/contact-us' className='btn btn-secondary-gradient py-sm-3 px-4 px-sm-5'>Get In Touch</a>
                    </div>
                    <div className='col-lg-5 hero-image-wr'>
                        <figure>
                            <img src='/img/custom_voip_1.webp' alt='Custom VOIP Softphone' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='about-block'>
        <div  id="about" className="container py-5 px-lg-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6">
                        <img className='img-fluid' width={588} height={407} alt="Fusion PBX" src="https://admin.fonimo.app/uploads/About_95e5bbe747.webp"  />
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">Lorem Ipsum is simply dummy</h5>
                    <h2 className="mb-4">What is FusionPBX?</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> 
                </div>
            </div>
        </div>
      </section>

      <section className='cta-block'>
        <div className="container py-5">
            <div className='row py-2 align-items-center justify-content-center'>
                <div className="col-lg-6 col-12">
                    <h3 className="text-white mb-4 animated slideInDown">Understanding Softphones: The Future of Telephony </h3>
                    <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> 
                    <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Schedule a Call</a>
                </div>
                <div className='col-lg-4 col-12'>
                    <figure>
                        <img src='/img/Partner_2.webp' alt='CTA' />
                    </figure>
                </div>
            </div>
        </div>
    </section>

      <section className='requirements-blk'>
        <div className='requiremensts-inner py-5'>
            <div className='container px-lg-4'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <h2 >Benefits of Integrating FusionPBX with Softphones </h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> 
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-3 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4'>
                            <figure>
                                <img width="60" height="60" className='img-fluid' style={{width: "60px", margin: "0 auto"}} src='/img/white-label-softphone/Custom-Branding.svg' alt='IP PBX Solution'/>
                            </figure>
                            <h4 className='mt-3'>#Benefit1</h4>
                            <p className='mt-3'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4'>
                            <figure>
                                <img width="60" height="60" className='img-fluid' style={{width: "60px", margin: "0 auto"}} src='/img/white-label-softphone/Excellent-Features.svg' alt='IP PBX Solution'/>
                            </figure>
                            <h4 className='mt-3'>#Benefit2</h4>
                            <p className='mt-3'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4'>
                            <figure>
                                <img width="60" height="60" className='img-fluid' style={{width: "60px", margin: "0 auto"}} src='/img/white-label-softphone/Customization-and-Integrations.svg' alt='IP PBX Solution'/>
                            </figure>
                            <h4 className='mt-3'>#Benefit3</h4>
                            <p className='mt-3'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4'>
                            <figure>
                                <img width="60" height="60" className='img-fluid' style={{width: "60px", margin: "0 auto"}} src='/img/white-label-softphone/Wide-Audience.svg' alt='IP PBX Solution'/>
                            </figure>
                            <h4 className='mt-3'>#Benefit4</h4>
                            <p className='mt-3'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="about-block" style={{ width: '100%', minHeight: '500px' }}>
      <div className="container py-5 px-lg-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="text-primary-gradient fw-medium">Lorem Ipsum is simply dummy</h5>
            <h2 className="mb-4">Step-by-Step Guide: Setting Up FusionPBX with Your Softphone</h2>
            <p>
              <i className="fa fa-check text-primary-gradient me-3"></i>
              <b>Step 1</b>
              <br />
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p>
            <p>
              <i className="fa fa-check text-primary-gradient me-3"></i>
              <b>Step 2</b>
              <br />
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p>
            <p>
              <i className="fa fa-check text-primary-gradient me-3"></i>
              <b>Step 3</b>
              <br />
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p>
          </div>
          <div className="col-lg-6">
            <img
              className="img-fluid"
              width="588"
              height="407"
              alt="About"
              src="https://admin.fonimo.app/uploads/About_95e5bbe747.webp"
            />
          </div>
        </div>
      </div>
    </section>

    <section className='offerings-blk py-lg-4'>
        <div className='offerings-inner py-5'>
            <div className='container px-lg-4'>
            <div className='row align-items-center'>
                    <div className='col-lg-12 pe-5 text-center'>
                        <h3 className='mb-4'>Advanced Features in FusionPBX for Seamless Softphone Integration</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> 
                    </div>
                </div>
                <div className='row align-items-center justify-content-around'>
                    
                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Unified-Brand-Consistency .svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Multiple Domain Support</h4>
                                <h6>Features</h6>
                                <ul className='ps-3'>
                                    <li>Curabitur bibendum ante non quam varius faucibus.</li>
                                    <li>Nullam venenatis nisi eget porttitor pulvinar.</li>
                                    <li>Nunc sit amet erat sed quam fringilla ornare.</li>
                                    <li>Etiam viverra augue et eleifend ultrices.</li>
                                    <li>Sed gravida est eget lorem consequat sodales.</li>
                                    <li>Sed quis neque suscipit, interdum purus vitae, porttitor sapien.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Tailored-to-Business-Needs.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Video Call Support</h4>
                                <h6>Features</h6>
                                <ul className='ps-3'>
                                    <li>Curabitur bibendum ante non quam varius faucibus.</li>
                                    <li>Nullam venenatis nisi eget porttitor pulvinar.</li>
                                    <li>Nunc sit amet erat sed quam fringilla ornare.</li>
                                    <li>Etiam viverra augue et eleifend ultrices.</li>
                                    <li>Sed gravida est eget lorem consequat sodales.</li>
                                    <li>Sed quis neque suscipit, interdum purus vitae, porttitor sapien.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row align-items-center justify-content-around'>
                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Improved-Customer-Engagement.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Push Notifications</h4>
                                <h6>Features</h6>
                                <ul className='ps-3'>
                                    <li>Curabitur bibendum ante non quam varius faucibus.</li>
                                    <li>Nullam venenatis nisi eget porttitor pulvinar.</li>
                                    <li>Nunc sit amet erat sed quam fringilla ornare.</li>
                                    <li>Etiam viverra augue et eleifend ultrices.</li>
                                    <li>Sed gravida est eget lorem consequat sodales.</li>
                                    <li>Sed quis neque suscipit, interdum purus vitae, porttitor sapien.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/Custom-VoIp-Softphone/Competitive-Advantage.svg" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Message Broadcast</h4>
                                <h6>Features</h6>
                                <ul className='ps-3'>
                                    <li>Curabitur bibendum ante non quam varius faucibus.</li>
                                    <li>Nullam venenatis nisi eget porttitor pulvinar.</li>
                                    <li>Nunc sit amet erat sed quam fringilla ornare.</li>
                                    <li>Etiam viverra augue et eleifend ultrices.</li>
                                    <li>Sed gravida est eget lorem consequat sodales.</li>
                                    <li>Sed quis neque suscipit, interdum purus vitae, porttitor sapien.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      </section>

    <section className='download_cta_block my-5'>
        <div className='download_cta_inner text-center p-5'  style={{borderRadius:"0"}}>
            <div className='container'>
                <h3 className='text-white mb-5'>Improve call quality and productivity with FusionPBX Softphone integration.</h3>
                <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Contact Us</a>
            </div>
        </div>
    </section>

    <section className='features-block mt-4' id="feature">
        <div className="container-xxl py-5">
            <div className="container px-lg-5">
                <div className="text-center wow fadeInUp mb-5" data-wow-delay="0.1s">
                    <h3 className="mb-3">Enhancing User Experience with FusionPBX Softphone Integration</h3>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                </div>
                <div className="row g-4">

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Cutting-Edge-Technologies.svg" alt="" />
                            </div>
                            <h5 className="mb-3">#Integration 1</h5>
                            <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/WebRTC-Support.svg" alt="" />
                            </div>
                            <h5 className="mb-3">#Integration 2</h5>
                            <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <img width={40} height={40} style={{width: "40px"}} className="img-fluid" src="/img/Custom-VoIp-Softphone/Custom-Integration.svg" alt="" />
                            </div>
                            <h5 className="mb-3">#Integration 3</h5>
                            <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

    <section className="why-choose-block">
        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-5">FAQ about FusionPBX and Softphonaes</h3>
                </div>
                <div className="row gy-5 gx-4 justify-content-center">
                <div className="faq-wrap">
                        <div className="faq-set">
                            <h4 className='active'>What are the benefits of integrating FusionPBX with softphones? <i className="fas fa-angle-down"></i></h4>
                            <div className="content" style={{display: "block"}}>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>Can you customize FusionPBX features to suit our business requirements? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>What makes your FusionPBX Softphone solution unique? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>Do you offer support during the setup process? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>How does your product simplify the management of FusionPBX? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>Can your solution be integrated with existing IT infrastructure? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default FusionPbx