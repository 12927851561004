import React from 'react'
import ContactForm from '../components/common/ContactForm.js';
import Meta from '../components/common/Meta'

const metaData = {
    meta : {
        meta_title: "Discover the Future of Unified Communications at UCX Expo 2024",
        meta_desc: "Join us at UCX Expo 2024 to explore cutting-edge unified communications technologies. Network with industry leaders and stay ahead of future trends.",
    }
}

const Expo = () => {
    document.body.classList.add('expo-page');
  return (
    <>
    <Meta value={metaData} canonical="https://fonimo.app/itexpo" />
    <section className='ucx-expo-blk'>
        <div className='ucx-inner-wr'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-sm-12 p-0 px-5'>
                        <figure>
                            <img src='/img/expo.png' title='ITEXPO 2025' alt='ITEXPO 2025' />
                        </figure>
                    </div>
                    <div className='col-lg-6 col-sm-12 p-0 px-5'>
                        <h1 className='text-center mb-3'>Book a Meeting</h1>
                        <ContactForm class="expo-form"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='requirements-blk' style={{backgroundColor:"#f9f9f9"}}>
        <div className='requiremensts-inner py-5'>
            <div className='container px-lg-4'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='text-center'>Your Vision, Our Expertise – The Perfect Partnership</h2>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-4 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4'>
                            <h4 className='mt-3'>Exclusive Products Demo</h4>
                            <p className='mt-3'>Discover seamless communication with Fonimo, our advanced WebRTC-based VoIP softphone. Designed for effortless connectivity, Fonimo empowers businesses with crystal-clear voice, video calls, and messaging. Experience the future of unified communications with unmatched reliability and performance.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4' style={{minHeight:"292px"}}>
                            <h4 className='mt-3'>Industry Insights</h4>
                            <p className='mt-3'>Learn directly from our expert through live presentations and panel discussions on emerging trends like AI-powered communications, next-gen VoIP, and unified collaboration tools.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 text-center mb-4'>
                        <div className='bg-light rounded text-center p-4' style={{minHeight:"292px"}}>
                            <h4 className='mt-3'>Personalized Consultation</h4>
                            <p className='mt-3'>Our expert will be available for one-on-one consultations to discuss how Fonimo can optimize your business communication strategies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='team-blk' style={{backgroundColor:"transparent"}}>
        <div className='container'>
            <div className='team-inner-wr'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-lg-12 text-center'>
                        <h2>Meet Us at ITEXPO</h2>
                        <p>Collaborate with experts who understand your challenges and deliver customized solutions to help you progress confidently. Connect with professionals who get your unique challenges and provide tailored solutions to help you realize your potential. Let’s address what’s preventing you.</p>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-lg-5 col-md-6 col-sm-8'>
                                <div className='team-card'>
                                    <figure>
                                        <img style={{maxWidth:"220px",margin:"0 auto"}} src='/img/Arpit-Modi-new.png' alt='Arpit Modi'/>
                                    </figure>
                                    <h3>Arpit Modi</h3>
                                    <h5>Co-Founder & CEO at Inextrix Technologies</h5>
                                    <a style={{fontSize:"28px"}} className="linkedin-url" href="https://www.linkedin.com/in/arpit-modi-a174b710/" target="_blank">
                                        <i aria-hidden="true" className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-6 col-sm-8'>
                                <div className='team-card'>
                                    <figure>
                                        <img style={{maxWidth:"220px",margin:"0 auto"}} src='/img/Samir-Doshi-new.png' alt='Samir Doshi'/>
                                    </figure>
                                    <h3>Samir Doshi</h3>
                                    <h5>Co-Founder & CTO at Inextrix Technologies</h5>
                                    <h5>Founder of ASTPP​</h5>
                                    <a style={{fontSize:"28px"}} className="linkedin-url" href="https://in.linkedin.com/in/smrdoshi" target="_blank">
                                        <i aria-hidden="true" className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='cta-block'>
        <div className="container py-5">
            <div className='row py-2 align-items-center justify-content-center'>
                <div className="col-lg-6 col-12">
                    <h2 className="text-white pb-1 animated slideInDown">Get In Touch</h2>
                    <p className="text-white mb-4">Ready to dive into the future of communication? Book a meeting with us at ITExpo 2025 and see how Inextrix Technologies can take your business communication to the next level. Don’t miss out on this opportunity to experience our innovations first-hand!</p>
                    <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3">Contact Us</a>
                </div>
                <div className='col-lg-4 col-12'>
                    <figure>
                        <img src='/img/Partner_2.webp' alt='CTA' />
                    </figure>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Expo